<template>
  <div id="Page-User-Register" class="w720">
		<!-- <div class="title-h2">REGISTER</div> -->
		<div class="title-h1">{{L("注册")}}</div>
		<div class="mt-20"></div>
		<div class="form-box register">
			<div class="form-item e-mail" :class="{error:!formRule.email}">
				<div class="label">{{L("电子邮箱")}}<span>*</span></div>
				<div class="input"><input spellcheck="false" ref="email" v-model="formData.email" @change="checkEmail" placeholder="E-Mail" type="text" name="email" autocomplete="off"/></div>
			</div>
			<div class="form-item password" :class="{error:!formRule.password}">
				<div class="label">{{L("密码")}}<span>*</span><span class="form-tips" v-if="!formRule.password">（{{L("密码必须至少6位，并包含英文字母")}}）</span></div>
				<div class="input"><input spellcheck="false" ref="password" v-model="formData.password" @change="checkPassword" placeholder="Password" :type="passwordHide?'password':'text'" name="password" autocomplete='new-password'/><PasswordHide @click.native="toggleEye('passwordHide')" :hide="passwordHide"  /></div>
			</div>
			<div class="form-item repassword" :class="{error:formData.password &&!formRule.repassword}">
				<div class="label">{{L("确认密码")}}<span>*</span><span class="form-tips" v-if="formData.password && !formRule.repassword">（{{L("两次输入密码不一致")}}）</span></div>
				<div class="input"><input spellcheck="false" ref="repassword" v-model="formData.repassword" @change="checkPasswordConfirm" placeholder="Retype Password" :type="repasswordHide?'password':'text'" name="repassword" autocomplete='new-password'/><PasswordHide @click.native="toggleEye('repasswordHide')" :hide="repasswordHide" /></div>
			</div>
			<div class="form-item verification-code" :class="{error:!formRule.verificationCode}">
				<div class="label">{{L("验证码")}}<span>*</span></div>
				<div class="input">
					<input spellcheck="false" ref="verificationCode" v-model="formData.verificationCode" @change="checkCode" @keyup.enter="doSubmit" placeholder="Verification Code" type="text" name="verificationCode" maxlength="4" autocomplete="off"/>
					<VerificationCode ref="VerificationCode" type="FRONT_DESK_USER_REGISTER"/>
				</div>
			</div>
			
			<div class="form-tips">
				<p>{{L("*号为必填项")}}</p>
			</div>
			
			<div class="form-btn">
				<div class="flex between">
					<div><span class="button dark" :class="{loading}" @click="doSubmit">{{L("注册登录")}}</span></div> 
					<!-- /api/v1/frontDeskUser/register -->
				</div>
			</div>
		</div>
  </div>
</template>

<script>
import VerificationCode from '/src/components/VerificationCode.vue';
import PasswordHide from '/src/components/PasswordHide.vue';
export default {
  name: 'Page-User-Register',
  components: {
    VerificationCode,
		PasswordHide
  },
  data(){
    return {
			formRule:{
				email:true,
				password:true,
				repassword:true,
				verificationCode:true,
			},
			formData:{
				email:"",
				password:"",
				repassword:"",
				verificationCode:"",
			},
			passwordHide:true,
			repasswordHide:true,
			loading:false
    };
  },
  async mounted () {
	
  },
  methods: {
		// this.$refs.VerificationCode.doChanges();
		toggleEye(target){
			this[target] = !this[target];
		},
		checkEmail(){
			if(!this.$root.checkEmail(this.formData.email)){
				this.formRule.email = false;
				this.$refs.email.focus();
				return false;
			}
			this.formRule.email = true;
			return true;
		},
		checkPassword(){
			if(!this.$root.checkPassword(this.formData.password)){
				this.formRule.password = false;
				this.$refs.password.focus();
				return false;
			}
			this.formRule.password = true;
			return true;
		},
		checkPasswordConfirm(){
			if(!this.$root.checkPasswordConfirm(this.formData.password,this.formData.repassword)){
				this.formRule.repassword = false;
				this.$refs.repassword.focus();
				return false;
			}
			this.formRule.repassword = true;
			return true;
		},
		checkCode(){
			if(!this.$root.checkCode(this.formData.verificationCode)){
				this.formRule.verificationCode = false;
				this.$refs.verificationCode.focus();
				return false;
			}
			this.formRule.verificationCode = true;
			return true;
		},
		checkAll(){
			return this.checkEmail() && this.checkPassword() && this.checkPasswordConfirm() && this.checkCode();
		},
		doSubmit(){
			if(!this.$root.CONFIG.onRegister){
				alert("网站暂停注册. 具体开放时间请留意网站信息.");
				return;
			}
			if(!this.checkAll()){return false;}
			this.loading = true;
			var formData = {...this.formData};
			delete formData.repassword;
			this.request.post('/api/v1/frontDeskUser/register',formData).then(res=>{
				this.loading = false;
				if(res){
					alert(this.L("注册成功"))
					this.$router.push("/User/Login")
				} else {
					this.$refs.VerificationCode.doChanges();
				}
			});
		}
  }
}
</script>

<style scoped lang="less">
.form-box.register{
	width:720px;
	font-size:18px;
}
</style>